import { getNotificationsReq } from '@services/notifications';
import axios, { AxiosRequestConfig } from 'axios';
import { prizeToast } from './toast';
import jwt from 'jsonwebtoken';
import { secretJWTAPI } from './consts_envs';
import { SESSION_DATA_KEY } from '@context/sessionContext';

const fetchWithInterceptor = axios.create();

const fetch = axios.create();

const middlewareAuth = (req: AxiosRequestConfig<any>) => {
  if (typeof window !== 'undefined') {
    if (!req.headers || !req.headers.Authorization) {
      const localStorageUserData = localStorage.getItem(SESSION_DATA_KEY);

      let user: any = undefined;
      if (localStorageUserData != null) {
        user = JSON.parse(localStorageUserData);
      }

      const token = jwt.sign(
        {
          user,
        },
        secretJWTAPI
      );

      req.headers = {
        Authorization: `Bearer ${token}`,
      };
    }
  }

  return req;
};

fetch.interceptors.request.use(middlewareAuth, (error) => {
  return Promise.reject(error);
});

fetchWithInterceptor.interceptors.request.use(middlewareAuth, (error) => {
  return Promise.reject(error);
});

// Add a response interceptor
fetchWithInterceptor.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if (response.data && response.data.has_prize) {
      getNotificationsReq({ has_viewed: false, type: 0 }).then(
        (notifications) => {
          if (notifications && Array.isArray(notifications)) {
            prizeToast({
              notifications: notifications,
            });
          }
        }
      );
    }
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export default {
  fetchWithInterceptor,
  fetch,
};
