export const loginUrl =
  process.env.NEXT_PUBLIC_LOGIN_URL || 'https://clarooferta.com/prezaopremiado/login';

export const zwasAPIUrl =
  process.env.ZAWS_API_URL || 'https://api.zed.qa.lumminy.com/v1';


export const secretJWTAPI = 
  process.env.NEXT_PUBLIC_JWT_SECRET_API || "VGhlIFNlY3JldCBmcm9tIGEgcHJvbW90aW9uIHRoYXQgd2lsbCBiZSBhIHN1Y2Nlc3M="

export const modalRegisterID = "modal-register"