import { Promotion } from '@interfaces/Promotion';
// import { getPromotionReq } from '@services/promotion';
import React, {
  createContext,
  useContext,
  // useEffect,
  useState,
} from 'react';
import StagesProvider from './stagesContext';
// import LoginInfosProvider from './loginInfosContext';
// import setLanguage from 'next-translate/setLanguage';
// import Loading from '@components/Loading';
// import logger from '@logger/logger';

interface PromotionContextInterface {
  promotion: Promotion;
}

interface PromotionInterfaceProps {
  children: React.Component | any;
}

export const PromotionContext = createContext<PromotionContextInterface>(
  {} as PromotionContextInterface
);

export default function PromotionProvider({
  children,
}: PromotionInterfaceProps) {
  // const [loading, setLoading] = useState<boolean>(false);
  const [promotion, _] = useState<Promotion>({
    promotionCode: '',
    configs: {
      displayName: String(process.env.NEXT_PUBLIC_APP_NAME),
      lang: 'es',
    },
  });

  // const loadPromotion = async () => {
  //   const promotionResp = await getPromotionReq();
  //   logger.debug('loadPromotion:', promotionResp);
  //   if (promotionResp != null) {
  //     // if (promotion.configs.lang != promotionResp.configs.lang) {
  //     //   setLanguage(promotionResp.configs.lang);
  //     // }
  //     setPromotion(promotionResp);
  //   }
  //   setLoading(false);
  // };

  // useEffect(() => {
  //   loadPromotion();
  //   // setLanguage(promotion.configs.lang);
  //   setLoading(true);
  // }, []);

  return (
    <PromotionContext.Provider
      value={{
        promotion,
      }}
    >
      <StagesProvider>
        {/* <LoginInfosProvider>
        </LoginInfosProvider> */}
        {children}
      </StagesProvider>
    </PromotionContext.Provider>
  );
}

export const usePromotion = () => {
  return useContext(PromotionContext);
};
