import { User } from '@interfaces/db/User';
import React, { createContext, useEffect, useState } from 'react';


interface SessionContextInterface {
  signIn(userData:User):void;
  signOut():void;
  status: "unauthenticated" | "loading"| 'authenticated';
  data?:{
    user?:User;
  }
}

interface SessionInterfaceProps {
  children: React.Component | any;
}

export const SessionContext = createContext<SessionContextInterface>(
  {} as SessionContextInterface
);

export const SESSION_DATA_KEY = "udp_b70ce1c4-1441-4f5f-9a2f-068afafc0404"

export default function SessionProvider({ children }: SessionInterfaceProps) {
  const [user, setUser ] = useState<User>();
  const [status, setStatus] = useState<"unauthenticated" | "loading"| 'authenticated'>("loading");
  
  useEffect(()=>{
    const localStorageData = localStorage.getItem(SESSION_DATA_KEY);
    if(localStorageData == null){
      setStatus("unauthenticated");
    }else if(user == undefined) {
      setStatus("authenticated");
      setUser(JSON.parse(localStorageData) as User)
    }
  },[,user])

  const signIn = (userData:User)=>{
    localStorage.setItem(SESSION_DATA_KEY,JSON.stringify(userData))
    setUser(userData)
    setStatus("loading");
  }


  const signOut = () => {
    localStorage.removeItem(SESSION_DATA_KEY)
    setUser(undefined)
    setStatus("loading");
  }

  return (
    <SessionContext.Provider
      value={{ signIn,signOut,data:user?{user}:undefined, status }}
    >
      {children}
    </SessionContext.Provider>
  );
}