const colors = {
  lpHeaderScrollColor: '#D92A1C',
  lpBodyColorGradient:
    'linear-gradient(180deg,#DA291C 0%, #DA291C 13.54%, #DA291C 55.73%);',
  lpBannerTitleBoldColor: '#DA291C',
  lpFooterColor: '#ffffff',
  lpBannerTitleColor: '#DA291C',
  lpBannerPrizeColor: '#DA291C',
  lbHeaderTextColor: '#DA291C',
};

const fonts = {
  headerLinks: {
    fontFamily: 'mainfont',
    fontWeight: 400,
    color: 'black',
  },
};

const components = {
  Link: {
    variants: {
      //LandingPage Header Links
      lpHeaderLinks: {
        fontFamily: 'AMX',
        fontWeight: 500,
        fontStyle:"normal",
        display: { base: 'none', md: 'flex' },
        _hover: { textDecor: 'underline' },
        color: '#FFFFFF',
        fontSize:"20px"
      },
      lpFooterLinks: {
        fontSize: ['20px', '30px'],
        fontWeight: 700,
        color: 'white',
        fontFamily: 'mainfont',
        _hover: { textDecor: 'underline' },
      },
    },
  },
  Heading: {
    variants: {
      lpBannerTitle: {
        color: '#DA291C',
        // letterSpacing: '1px',
        fontWeight: 700,
        fontSize: ['20px', '28px','28px','28px'],
        textAlign: 'start',
        fontFamily: 'AMX',
      },
      lpBannerTitle2: {
        color: '#033B6C',
      },
      lpBootomTitle:{
        color: '#DA291C',
        letterSpacing: '1px',
        fontWeight: '700',
        lineHeight:"34px",
        fontSize: ['18px', '24px','24px','24px'],
        textAlign: 'start',
        fontFamily: 'AMX',
      },
      lpBannerBootomTitle:{
        color: '#0F37A0',
        letterSpacing: '1px',
        
        fontWeight: '900',
        fontSize: ['29px', '18px', '30px', '38px', '45px', '45px'],
        textAlign: 'center',
        fontFamily: 'Montserrat',
      }
    },
  },
  Text: {
    variants: {
      tableHeaderText:{
        color: "#000",
        fontFamily: "AMX",
        fontSize: "22px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "22px" /* 100% */
      },
      tableBodyText:{
        color: "#000",
        fontFamily: "AMX",
        fontSize: "22px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "22px" /* 100% */
      },
      lpDescriptionInfos: {
        // textAlign: ['start'],
        fontSize: ['16px', '16px', '18px', '18px'],
        color: '#000000',
        fontFamily: 'AMX',
        fontWeight: 400,
        fontStyle:"normal",
        lineHeight: ['16px', '16px', '30px', '30px'],
      },
      lpBannerTitle: {
        fontFamily: 'mainfont',
        textAlign: 'center',
        fontSize: ['30px', '40px'],
        color: 'lpBannerTitleColor',
        fontWeight: 700,
        lineHeight: '100%',
      },
      lpBannerTitle2: {
        textAlign: ['start'],
        fontSize: ['16px', '16px', '18px', '24px'],
        color: '#000000',
        fontFamily: 'AMX',
        fontWeight: 500,
        lineHeight: '140%',
      },
      lpBootomText: {
        textAlign: ['start'],
        fontSize: ['16px', '16px', '16px', '20px'],
        color: '#000',
        fontFamily: 'AMX',
        fontWeight: 400,
        lineHeight: '30px',
      },
      lpFooterText: {
        fontWeight: 400,
        fontFamily: 'Inter',
        color: 'white',
        fontSize: ['8px', '8px', '8px', '10px', '12px'],
        textAlign: ['justify', 'center'],
      },
      lpBannerBootomText:{
        w: '80%',
        textAlign: ['center'],
        fontSize: ['17px', '10px', '14px', '17px', '21px', '22px'],
        color: '#0F37A0',
        fontFamily: 'Montserrat',
        fontWeight: 600,
      }
    },
  },
  Button: {
    variants: {
      lpHeaderButton: {
        borderRadius: 60,
        pl: 10,
        pr: 10,
        bgColor: '#E80706',
        _hover: { bg: 'orange.700' },
        _active: { bg: 'orange.900' },
        fontFamily: 'mainfont',
        fontSize: '18px',
        fontWeight: 700,
        color: 'white',
      },
      // color: #000;
      // text-align: center;
      // font-family: AMX;
      // font-size: 12px;
      // font-style: normal;
      // font-weight: 500;
      // line-height: normal;
      lpBannerButton: {
        fontWeight: 500,
        fontFamily: 'AMX',
        lineHeight: 'normal',
        color: '#000',
        fontSize: ['12px', '13px', '17px', '17px', '21px', '25px'],
        bgColor: '#FBE654',
        _hover: { bg: 'blue.700' },
        _active: { bg: 'blue.900' },
        pl: ['6','8', '9'],
        pt: ['2','4', '5'],
        pb: ['2','4', '5'],
        pr: ['6','8', '9'],
        borderRadius: '43.5px',
      },
      lpBannerBootomButton:{
        fontWeight: 700,
        fontFamily: 'Montserrat',
        lineHeight: '30.48px',
        color: '#FFFFFF',
        fontSize: ['17px', '13px', '17px', '17px', '21px', '26px'],
        bgColor: '#DA0100',
        _hover: { bg: 'blue.700' },
        _active: { bg: 'blue.900' },
        pl: ['8', '9'],
        pt: ['4', '5'],
        pb: ['4', '5'],
        pr: ['8', '9'],
        borderRadius: '43.5px',
      },
      lpLoginButton: {
        fontFamily: 'mainfont',
        borderWidth: 'hex',
        borderColor: '#1D1D1D',
        border: '1px solid black',
        _hover: { bg: 'blue.700' },
        _active: { bg: 'blue.900' },
        outline: '2px solid transparent',
        outlineOffset: '2px',
        color: '#1D1D1D',
      },
    },
  },
};

export { colors, fonts, components };
