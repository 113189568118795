
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    // import { SessionProvider } from 'next-auth/react';
import type { AppProps } from 'next/app';
import { ReactElement, ReactNode, useEffect } from 'react';
import type { NextPage } from 'next';
import CustomThemeProvider from '@context/themeContext';
import Head from 'next/head';
import '@fontsource/montserrat/700.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/raleway/700.css';
import '@fontsource/raleway/400.css';
import '@fontsource/inter';
import '@fontsource/abeezee';
import '@fontsource/dm-sans';
import '@fontsource/raleway';
import '@fontsource/montserrat';
import WarnMeModule from '@modules/WarnMe';
import LayoutWarnMe from '@layout/WarnMePage';
import '@styles/global.css';
import * as gtag from '@lib/gtag';
import Analytics from '@components/GoogleAnalytics';
import { useRouter } from 'next/router';
import PromotionProvider from '@context/promotionContext';
import SessionProvider from '@context/sessionContext';
type NextPageWithLayout = NextPage & {
    getLayout?: (page: ReactElement, pageProps?: any) => ReactNode;
};
type AppPropsWithLayout = AppProps & {
    Component: NextPageWithLayout;
    showWarnMe: boolean;
};
function App(props: AppPropsWithLayout) {
    const { Component, pageProps: initialPageProps, showWarnMe } = props;
    const { session, ...pageProps } = (initialPageProps as any) ?? ({} as any);
    const getLayout = showWarnMe
        ? (page: any) => <LayoutWarnMe>{page}</LayoutWarnMe>
        : Component.getLayout ?? ((page) => page);
    const router = useRouter();
    useEffect(() => {
        const handleRouteChange = (url: any) => {
            gtag.pageview(url);
        };
        router.events.on('routeChangeComplete', handleRouteChange);
        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, [router.events]);
    const Heading = () => {
        return (<Head>
        <link rel="icon" href="/imgs/favicon.ico"/>
        <title>Promoção {process.env.NEXT_PUBLIC_APP_NAME}</title>
        <meta property="og:title" content={`Promoção ${process.env.NEXT_PUBLIC_APP_NAME}`} key="title"/>
      </Head>);
    };
    if (showWarnMe) {
        return (<PromotionProvider>
        <CustomThemeProvider>
          {getLayout(<div>
              <Heading />
              <WarnMeModule />
              <Analytics />
            </div>)}
        </CustomThemeProvider>
      </PromotionProvider>);
    }
    return (<SessionProvider>
      <PromotionProvider>
        <CustomThemeProvider>
          {getLayout(<div>
              <Heading />
                <Component {...pageProps}/>
              <Analytics />
            </div>, pageProps)}
        </CustomThemeProvider>
      </PromotionProvider>
    </SessionProvider>);
}
App.getInitialProps = async () => {
    // Use the layout defined at the page level, if available
    // const ENV = process.env.NEXT_PUBLIC_NODE_ENV;
    const SHOW_NEWSLETTER = process.env.SHOW_NEWSLETTER;
    let showWarnMe = false;
    if (SHOW_NEWSLETTER == 'true') {
        showWarnMe = true;
    }
    return { showWarnMe };
};
const __Next_Translate__Page__18c5fb7194f__ = App;

    export default __appWithI18n(__Next_Translate__Page__18c5fb7194f__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  