import { extendTheme } from '@chakra-ui/react';

import {
  colors as colorsForm,
  fonts as fontsForm,
  components as componentsForm,
} from './forms-azul';

import {colors,fonts, components} from "./landing-page-branco-vermelho"


const theme = extendTheme({
  colors: {
    ...colorsForm,
    ...colors
  },
  components: {
    FormLabel: {
      variants: {
        ...componentsForm.FormLabel.variants,
      },
    },
    Heading: {
      variants: {
        ...components.Heading.variants,
      },
    },
    Link: {
      variants: {
        ...components.Link.variants,
      },
    },
    Text: {
      variants: {
        ...components.Text.variants,
        ...componentsForm.Text.variants,
      },
    },
    Button: {
      variants: {
        ...components.Button.variants,
        ...componentsForm.Button.variants,
      },
    },
    Input: {
      variants: {
        ...componentsForm.Input.variants,
      },
    },
    Select: {
      variants: {
        ...componentsForm.Select.variants,
      },
    },
    NumberInputField: {
      variants: {
        ...componentsForm.NumberInputField.variants,
      },
    },
    Checkbox: {
      variants: {
        ...componentsForm.Checkbox.variants,
      },
    },
  },
  fonts: {
    heading: `'DM Sans', sans-serif`,
    body: `'DM Sans', sans-serif`,
    mainfont: `Montserrat`,
    ...fonts,
    ...fontsForm,
  },
  styles: {
    global: () => ({
      body: {
        overflow: 'auto',
        bg: '#f7fafc',
        color: '#000',
      },
    }),
  },
});

export { theme };